.indicators {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.indicator {
    width: 100%;
    max-width: 24px;
    height: 5px;
    background-color: var(--greyscale-06);
    border-radius: 1px;
}

.active {
    background-color: var(--secondary-03);
}

@media screen and (min-width: 426px) {
    .indicators {
        display: none;
    }
}