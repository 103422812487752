.bi {
    max-width: 100%;
    min-width: 100%;
}

.section-bi {
    padding: 30px;
}

@media screen and (max-width: 426px) {
    .section-bi {
        padding: 30px 0;
    }
}