.div-tooltip {
    display: block;
    position: absolute;
    top: 0;
    z-index: 2;
}

.tooltip {
    display: block;
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: var(--white);
    border: 1px solid var(--greyscale-07);
    filter: drop-shadow(-0 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    padding: 24px;
}

.div-tooltip.right,
.tooltip.right {
    left: calc(100% + 2px); /* confirmar mudança 18 -> 2 */
}

.div-tooltip.left,
.tooltip.left {
    right: calc( 100% + 2px);
    /* right: calc( 100% + 18px); */
}

.tooltip.right::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -22px;
    border-width: 22px;
    border-style: solid;
    border-color: transparent var(--white) transparent transparent;
}

.tooltip.left::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -22px;
    border-width: 22px;
    border-style: solid;
    border-color: transparent transparent transparent var(--white);
}

.tooltip > h4 {
    width: 367px;
    margin-bottom: 32px;
}

.tooltip > ul {
    list-style-position: outside;
    padding-left: 24px;
    margin-bottom: 48px;
}