.loader-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    z-index: 2;
}

.in-front {
    z-index: 1026;
}

.loader {
    border: 10px solid var(--greyscale-07);
    border-top: 10px solid var(--secondary-01);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}