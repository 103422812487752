.modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1025;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.modal {
    background-color: var(--white);
    border: 1px solid #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 32px 48px 86px;
}

.title-config {
    font-size: 20px;
    margin-bottom: 24px;
}

.body {
    gap: 24px;
}

.img {
    width: 118px;
    height: 118px;
}

.col-about {
    display: flex;
    gap: 24px;
}

.col-about-text > h5 {
    margin-bottom: 16px;
}

.col-about-text > p {
    margin-bottom: 12px;
}

.col-about-text > p:last-child {
    margin-bottom: 0;
}

.col-price {
    display: flex;
    align-items: flex-end;
}

.col-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media screen and (max-width: 767px) {
    .modal {
        padding: 24px 16px 48px;
    }

    .body {
        flex-direction: column;
    }

    .img {
        width: 66px;
        height: 66px;
        object-fit: cover;
    }

    .col-about-text > h5 {
        font-size: 16px!important;
        line-height: 140%!important;
    }
    
    .col-about-text > p {
        font-size: 12px!important;
        line-height: 14px!important;
    }

    .col-price > p {
        margin-left: 90px;
        font-size: 12px!important;
        line-height: 14px!important;
    }
}