.dropdown-logout {
    position: relative;
    margin: -40px 0;
    padding: 40px 0;
}

.dropdown-items-logout {
    position: absolute;
    top: 108px;
    left: -92px;
    width: 144px;
    padding: 0;
    background-color: var(--white);
    filter: drop-shadow(0px 4px 8px rgba(188, 188, 188, 0.25));
    border-radius: 8px;
    list-style: none;
}

.dropdown-items-logout > li {
    padding: 18px 40px;
}