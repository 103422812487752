:root {
    --primary-01: #00414C;
    --primary-02: #00343D;
    --primary-03: #0D4B55;
    --primary-04: #265D67;
    --primary-05: #407179;
    --primary-06: #59838A;
    
    --secondary-01: #42D1A3;
    --secondary-02: #35A782;
    --secondary-03: #4CD3A8;
    --secondary-04: #5ED8B1;
    --secondary-05: #71DDBA;
    --secondary-06: #84E1C3;
    
    --tertiary-01: #B7CD25;
    --tertiary-02: #92A41E;
    --tertiary-03: #BBD030;
    --tertiary-04: #C2D445;
    --tertiary-05: #C9DA5C;
    --tertiary-06: #D0DE71;

    --success: #00C247;
    --info: #004CE8;
    --warning: #FFE16A;
    --error: #FF3333;
    --disabled: #D8D8D8;
    --disabled-button: #879AC1;

    --greyscale-01: #212121;
    --greyscale-02: #424242;
    --greyscale-03: #616161;
    --greyscale-04: #757575;
    --greyscale-05: #9E9E9E;
    --greyscale-06: #BDBDBD;
    --greyscale-07: #E0E0E0;
    --greyscale-08: #EEEEEE;
    --greyscale-09: #F5F5F5;
    --greyscale-10: #FAFAFA;

    --gradient-01: linear-gradient(90deg, #232222 5.99%, rgba(66, 66, 66, 0) 648.9%);
    --gradient-02: linear-gradient(90deg, #485563 0%, #29323C 100%);
    --gradient-03: linear-gradient(270deg, #000000 0%, #434343 106.17%);
    --gradient-04: linear-gradient(90deg, #08102D -35.87%, #062B5C 100%);

    --dark-01: #232E26;
    --dark-02: #344137;
    --dark-03: #1B271E;

    --white: #FFFFFF;
    --black: #000000;
    --red: #F54336;
    --pink: #EA1E61;
    --purple: #9D28AC;
    --deep-purple: #673AB3;
    --indigo: #3F51B2;
    --blue: #1A96F0;
    --light-blue: #00A9F1;
    --cyan: #00BCD3;
    --teal: #009689;
    --green: #4AAF57;
    --light-green: #8BC255;
    --lime: #CDDC4C;
    --yellow: #FFEB4F;
    --amber: #FFC02D;
    --orange: #FF981F;
    --deep-orange: #FF5726;
    --brown: #7A5548;
    --blue-grey: #607D8A;
    --grey: #F2F4F2;
}

/* PRIMARY */
.color-primary-01 {
    color: var(--primary-01);
}
.bg-primary-01 {
    background-color: var(--primary-01);
}

.color-primary-02 {
    color: var(--primary-02);
}
.bg-primary-02 {
    background-color: var(--primary-02);
}

.color-primary-03 {
    color: var(--primary-03);
}
.bg-primary-03 {
    background-color: var(--primary-03);
}

.color-primary-04 {
    color: var(--primary-04);
}
.bg-primary-04 {
    background-color: var(--primary-04);
}

.color-primary-05 {
    color: var(--primary-05);
}
.bg-primary-05 {
    background-color: var(--primary-05);
}

.color-primary-06 {
    color: var(--primary-06);
}
.bg-primary-06 {
    background-color: var(--primary-06);
}

/* SECONDARY */
.color-secondary-01 {
    color: var(--secondary-01);
}
.bg-secondary-01 {
    background-color: var(--secondary-01);
}

.color-secondary-02 {
    color: var(--secondary-02);
}
.bg-secondary-02 {
    background-color: var(--secondary-02);
}

.color-secondary-03 {
    color: var(--secondary-03);
}
.bg-secondary-03 {
    background-color: var(--secondary-03);
}

.color-secondary-04 {
    color: var(--secondary-04);
}
.bg-secondary-04 {
    background-color: var(--secondary-04);
}

.color-secondary-05 {
    color: var(--secondary-05);
}
.bg-secondary-05 {
    background-color: var(--secondary-05);
}

.color-secondary-06 {
    color: var(--secondary-06);
}
.bg-secondary-06 {
    background-color: var(--secondary-06);
}

/* TERTIARY */
.color-tertiary-01 {
    color: var(--tertiary-01);
}
.bg-tertiary-01 {
    background-color: var(--tertiary-01);
}

.color-tertiary-02 {
    color: var(--tertiary-02);
}
.bg-tertiary-02 {
    background-color: var(--tertiary-02);
}

.color-tertiary-03 {
    color: var(--tertiary-03);
}
.bg-tertiary-03 {
    background-color: var(--tertiary-03);
}

.color-tertiary-04 {
    color: var(--tertiary-04);
}
.bg-tertiary-04 {
    background-color: var(--tertiary-04);
}

.color-tertiary-05 {
    color: var(--tertiary-05);
}
.bg-tertiary-05 {
    background-color: var(--tertiary-05);
}

.color-tertiary-06 {
    color: var(--tertiary-06);
}
.bg-tertiary-06 {
    background-color: var(--tertiary-06);
}

/* ALERT & STATUS */
.color-success {
    color: var(--success);
}
.bg-success {
    background-color: var(--success);
}

.color-info {
    color: var(--info);
}
.bg-info {
    background-color: var(--info);
}

.color-warning {
    color: var(--warning);
}
.bg-warning {
    background-color: var(--warning);
}

.color-error {
    color: var(--error);
}
.bg-error {
    background-color: var(--error);
}

.color-disabled {
    color: var(--disabled);
}
.bg-disabled {
    background-color: var(--disabled);
}

.color-button-disabled {
    color: var(--button-disabled);
}
.bg-button-disabled {
    background-color: var(--button-disabled);
}

/* GREYSCALE */
.color-greyscale-01 {
    color: var(--greyscale-01);
}
.bg-greyscale-01 {
    background-color: var(--greyscale-01);
}

.color-greyscale-02 {
    color: var(--greyscale-02);
}
.bg-greyscale-02 {
    background-color: var(--greyscale-02);
}

.color-greyscale-03 {
    color: var(--greyscale-03);
}
.bg-greyscale-03 {
    background-color: var(--greyscale-03);
}

.color-greyscale-04 {
    color: var(--greyscale-04);
}
.bg-greyscale-04 {
    background-color: var(--greyscale-04);
}

.color-greyscale-05 {
    color: var(--greyscale-05);
}
.bg-greyscale-05 {
    background-color: var(--greyscale-05);
}

.color-greyscale-06 {
    color: var(--greyscale-06);
}
.bg-greyscale-06 {
    background-color: var(--greyscale-06);
}

.color-greyscale-07 {
    color: var(--greyscale-07);
}
.bg-greyscale-07 {
    background-color: var(--greyscale-07);
}

.color-greyscale-08 {
    color: var(--greyscale-08);
}
.bg-greyscale-08 {
    background-color: var(--greyscale-08);
}

.color-greyscale-09 {
    color: var(--greyscale-09);
}
.bg-greyscale-09 {
    background-color: var(--greyscale-09);
}

.color-greyscale-10 {
    color: var(--greyscale-10);
}
.bg-greyscale-10 {
    background-color: var(--greyscale-10);
}

/* DARK */
.color-dark-01 {
    color: var(--dark-01);
}
.bg-dark-01 {
    background-color: var(--dark-01);
}

.color-dark-02 {
    color: var(--dark-02);
}
.bg-dark-02 {
    background-color: var(--dark-02);
}

.color-dark-03 {
    color: var(--dark-03);
}
.bg-dark-03 {
    background-color: var(--dark-03);
}

/* OTHERS */
.color-white {
    color: var(--white);
}
.bg-white {
    background-color: var(--white);
}

.color-black {
    color: var(--black);
}
.bg-black {
    background-color: var(--black);
}

.color-red {
    color: var(--red);
}
.bg-red {
    background-color: var(--red);
}

.color-pink {
    color: var(--pink);
}
.bg-pink {
    background-color: var(--pink);
}

.color-purple {
    color: var(--purple);
}
.bg-purple {
    background-color: var(--purple);
}

.color-deep-purple {
    color: var(--deep-purple);
}
.bg-deep-purple {
    background-color: var(--deep-purple);
}

.color-indigo {
    color: var(--indigo);
}
.bg-indigo {
    background-color: var(--indigo);
}

.color-blue {
    color: var(--blue);
}
.bg-blue {
    background-color: var(--blue);
}

.color-light-blue {
    color: var(--light-blue);
}
.bg-light-blue {
    background-color: var(--light-blue);
}

.color-cyan {
    color: var(--cyan);
}
.bg-cyan {
    background-color: var(--cyan);
}

.color-teal {
    color: var(--teal);
}
.bg-teal {
    background-color: var(--teal);
}

.color-green {
    color: var(--green);
}
.bg-green {
    background-color: var(--green);
}

.color-light-green {
    color: var(--light-green);
}
.bg-light-green {
    background-color: var(--light-green);
}

.color-lime {
    color: var(--lime);
}
.bg-lime {
    background-color: var(--lime);
}

.color-yellow {
    color: var(--yellow);
}
.bg-yellow {
    background-color: var(--yellow);
}

.color-amber {
    color: var(--amber);
}
.bg-amber {
    background-color: var(--amber);
}

.color-orange {
    color: var(--orange);
}
.bg-orange {
    background-color: var(--orange);
}

.color-deep-orange {
    color: var(--deep-orange);
}
.bg-deep-orange {
    background-color: var(--deep-orange);
}

.color-brown {
    color: var(--brown);
}
.bg-brown {
    background-color: var(--brown);
}

.color-blue-grey {
    color: var(--blue-grey);
}
.bg-blue-grey {
    background-color: var(--blue-grey);
}

.color-grey {
    color: var(--grey);
}
.bg-grey {
    background-color: var(--grey);
}