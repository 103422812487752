.bg {
    background-color: var(--grey);
    padding-top: 100px;
    padding-bottom: 102px;
}

.logo {
    max-width: 264px;
    width: -webkit-fill-available;
    margin-bottom: 40px;
}

.col-category-greendex {
    justify-content: space-between;
}

.list-title {
    margin-bottom: 22px!important;
}

.list-items {
    list-style: none;
    padding-left: 0;
}

.list-items > li > a,
.list-items > li > p {
    color: #0A142F;
    mix-blend-mode: normal;
    opacity: 0.8;
}

.line {
    width: 100%;
    height: 1px;
    background: #0A142F;
    mix-blend-mode: normal;
    opacity: 0.2;
    border: 1px solid #979797;
    margin: 30px 0;
}

.copy {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #0A142F;
    mix-blend-mode: normal;
    opacity: 0.65;
}

@media screen and (max-width: 991px) {
    .bg {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .col-category-greendex {
        justify-content: space-evenly;
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        padding-bottom: 35px;
        margin-bottom: 40px;
    }

    .col-logo,
    .col-contact,
    .col-social {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .list-items > li > a {
        font-size: 14px;
        line-height: 30px;
    }

    .list-items > li > p {
        font-size: 14px;
        line-height: 16px;
    }
}