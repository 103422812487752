.header {
    height: auto;
    background-image: url('../../../assets/images/bg-carousel.webp');
    background-position: center;
    background-size: cover;
}

.carousel-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.item {
    color: var(--white);
    padding: 64px 90px;
}

.item > h3 {
    max-width: 417px;
    margin-bottom: 24px;
}

.item > h6 {
    max-width: 417px;
    margin-bottom: 48px;
    font-weight: 400;
}

.item > a > Button {
    max-width: 392px;
    white-space: normal;
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 30px;
    cursor: pointer;
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.left-arrow:focus,
.left-arrow:active,
.right-arrow:focus,
.right-arrow:active {
    outline: none;
}

.arrow-disabled {
    opacity: 0.7;
    cursor: default;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .item {
        padding: 32px 16px 24px 16px;
    }
    
    .item > h3 {
        font-size: 20px;
        line-height: 130%;
        margin-bottom: 16px;
    }
    
    .item > h6 {
        font-size: 14px;
        line-height: 125%;
        margin-bottom: 40px;
    }
    
    .item > a > Button {
        width: 100%;
        margin-bottom: 49px;
    }
}