@import './assets/theme/typography.css';
@import './assets/theme/colors.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: initial;
  text-decoration: initial;
}

html {
  overflow-x: hidden;
}

.label-register {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: #333333;
  margin-bottom: 8px;
}

.input-register {
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 16px;
  
  background: #F2F2F2;
  border: 0.5px solid #E5E5E5;
  border-radius: 6px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #808080;
}

.input-register:focus {
  outline: none;
}

.input-error {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: var(--deep-orange);
}

.input-success {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: var(--light-green);
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active,
.fade-enter-done {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active,
.fade-exit-done {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.blog > h1, .blog > h2, .blog > h3, .blog > h4, .blog > h5, .blog > h6 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.blog > h1 {
  font-size: 48px;
  line-height: 110%;
}

.blog > h2 {
  font-size: 40px;
  line-height: 110%;
}

.blog > h3 {
  font-size: 32px;
  line-height: 110%;
}

.blog > h4 {
  font-size: 24px;
  line-height: 110%;
}

.blog > h5 {
  font-size: 20px;
  line-height: 110%;
}

.blog > h6 {
  font-size: 18px;
  line-height: 110%;
}

.blog > p {
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.2px;
}

.blog > p > img {
  max-width: 100%;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-indent-1 {
  padding-left: 2.5rem;
}

.ql-indent-2 {
  padding-left: 3.5rem;
}

.ql-indent-3 {
  padding-left: 4.5rem;
}

.ql-indent-4 {
  padding-left: 5.5rem;
}

.ql-indent-5 {
  padding-left: 6.5rem;
}

.ql-indent-6 {
  padding-left: 7.5rem;
}

.ql-indent-7 {
  padding-left: 8.5rem;
}

.ql-indent-8 {
  padding-left: 9.5rem;
}

.ql-indent-9 {
  padding-left: 10.5rem;
}

.status-item-tooltip {
  height: 24px;
  width: 8px;
  border-radius: 2px;
}

.status-item-tooltip:hover {
  transform: scale(1.2);
}

.card-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}