.button {
    width: 100%;
    text-align: center;
    bottom: 24px;
    padding-top: 11px;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button:focus {
    outline: none;
}