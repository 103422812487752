.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 32px;
    list-style: none;
}

.breadcrumb-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--greyscale-04);
}

.breadcrumb-item > a{
    color: var(--greyscale-04);
}

.breadcrumb-item.active {
    font-weight: 500;
    color: var(--greyscale-02);
}

.breadcrumb-item.active > a {
    font-weight: 500;
    color: var(--greyscale-02);
}

.breadcrumb-item::before {
    content: '>';
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--greyscale-04);
    margin: 0 24px;
}

.breadcrumb-item:first-child:before {
    content: '';
    margin: 0;
}