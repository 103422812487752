.col-img {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/images/capa-login.png);
    background-size: cover;
    background-position: center;
}

.col-img img {
    position: absolute;
    bottom: 42px;
}

.back-button {
    display: none;
}

.logo-dark {
    margin-bottom: 56px;
    margin-top: 56px;
}

.row-continue {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 48px;
    margin-bottom: 32px;
}

.row-continue > .line {
    height: 1px;
    background-color: var(--greyscale-06);
    flex-grow: 1;
}

.row-continue > span {
    color: var(--greyscale-06);
}

.password-field > p:last-of-type {
    display: none;
}

.password-field > p:first-of-type {
    display: block;
}

.back-button {
    gap: 14px;
    margin-bottom: 24px;
    color: var(--greyscale-03);
}

.back-button:hover {
    color: var(--greyscale-03);
}

.back-button > icon {
    color: var(--greyscale-03);
    font-size: 12px;
    line-height: 140%;
}

.back-button > .back-button-text {
    color: var(--greyscale-03);
}

@media screen and (min-width: 767px) and (max-height: 910px) {
    .col-img {
        top: 0;
        position: sticky;
    }
}

@media screen and (max-width: 767px) {
    .col-img {
        height: 160px;
    }

    .col-img img {
        bottom: 24px;
    }

    .col-login img:nth-child(1) {
        display: none;
    }

    .back-button {
        position: absolute;
        top: 16px;
        gap: 14px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        color: var(--white);
    }
    
    .back-button > icon {
        color: var(--white);
        font-size: 12px;
        line-height: 140%;
    }
    
    .back-button > .back-button-text {
        color: var(--white);
    }

    .logo-dark {
        display: none;
    }

    .row-continue {
        margin-top: 40px;
        margin-bottom: 24px;
    }
}