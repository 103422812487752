.search {
    position: relative;
    width: 40%;
}

.search-input {
    width: 100%;
    padding: 10px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;

    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

.search-list {
    position: absolute;
    width: 100%;
    background-color: var(--white);
    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
    list-style: none;
    padding-left: 16px;
}

.search-list > a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-03);
}