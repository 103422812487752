.col-content {
    margin-top: 65px;
    margin-bottom: 248px;
}

.row-text {
    padding: 0 15px;
    display: flex;
    margin-bottom: 40px;
}

.search {
    position: relative;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 10px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;

    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

.list {
    padding: 0;
    display: flex;
    flex-direction: column;
    padding: 24px 64px;
    background-color: var(--white);
    border: 1px solid #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    list-style: none;
}

.order-data {
    direction: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #EEEEEE;
}

.order-data:last-of-type {
    border-bottom: none;
}

.order-image,
.user-image {
    width: 64px;
    height: 64px;
    border-radius: 3px;
    object-fit: cover;
}

.order-purchase-data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 24px;
}

.order-purchase-data > div {
    gap: 24px;
}

.paid {
    font-weight: semibold;
    color: var(--info);
}

.failed {
    font-weight: semibold;
    color: var(--red);
}

.pending {
    font-weight: semibold;
    color: var(--orange);
}

.d-flex-blog {
    display: flex;
}

@media screen and (max-width: 767px) {
    .list {
        padding: 32px 16px;
    }
}

@media screen and (max-width: 425px) {
    .col-content {
        margin-top: 32px;
        margin-bottom: 96px;
    }

    .title {
        font-size: 20px!important;
        line-height: 110%!important;
    }  
    
    .list {
        padding: 24px 16px;
        border-radius: 8px;
    }

    .order-data {
        margin: 0;
    }

    .order-data:nth-child(1) {
        padding-top: 0;
    }

    .order-data > div:nth-child(1) {
        flex-direction: column;
    }

    .user-data > div:nth-child(1) {
        align-items: flex-start!important;
    }

    .actions {
        margin-top: 8px;
        margin-left: 80px;
    }

    .order-image {
        width: 100%;
        height: 80px;
        margin-bottom: 16px;
    }

    .row-user-circle {
        gap: 16px;
    }

    .order-purchase-data {
        margin-left: 0;
        gap: 8px;
    }

    .order-purchase-data > div {
        flex-direction: column;
        gap: 8px;
    }

    .order-purchase-data > p:first-child {
        font-size: 16px!important;
        line-height: 140%!important;
        margin-bottom: 8px;
    }

    .d-flex-blog {
        flex-direction: column;
        gap: 24px;
    }

    .d-flex-blog > div {
        width: 100%;
    }
}

