.scroll-x {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-x::-webkit-scrollbar {
    display: none;
}

.filter-list {
    display: flex;
    justify-content: center;
    gap: 23px;
    width: max-content;
    margin: 0 auto;
}

@media screen and (max-width: 425px) {
    .filter-list {
        gap: 4px;
    }
}