.modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--grey);
    z-index: 1030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report-style-class {
    height: 100%;
    flex-grow: 1;
    margin-right: 20px;
    border: 3px solid var(--greyscale-06);
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-top: 20px;
}

.content {
    display: flex;
    width: 100%;
    height: calc(100% - 54px);
    margin-top: 20px;
    padding-bottom: 60px;
}

.filters-close {
    display: flex;
    padding: 16px;
    gap: 16px;
    flex-direction: column;
}

.filters-list {
    max-height: calc(100% / 2);
    overflow-y: scroll;
}

.filters-list::-webkit-scrollbar {
    background-color: transparent;
}
.filters-list::-webkit-scrollbar-thumb {
    background-color: var(--greyscale-06);
}

.filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.loader {
    border: 5px solid var(--greyscale-07);
    border-top: 5px solid var(--secondary-01);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.pre-save-report {
    max-height: 300px;
    overflow-y: scroll;
    margin-right: -48px;
    padding-right: 48px;
}

.pre-save-report::-webkit-scrollbar {
    background-color: transparent;
}
.pre-save-report::-webkit-scrollbar-thumb {
    background-color: var(--greyscale-08);
}

@media screen and (max-width: 425px) {
    .content {
        flex-direction: column;
    }

    .filters-close {
        flex-direction: row;
    }

    .filters-list {
        max-height: calc(100% / 5);
    }
}