.section {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-image: url('../../../assets/images/memphis-home.png');
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.container {
    min-height: calc(100vh - 100px);
}

.row {
    min-height: calc(100vh - 100px);
    padding: 48px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin-right: -32px!important;
}

.text {
    font-size: 18px!important;
    font-weight: 100;
    font-style: italic;
}

.description {
    width: 75%
}

.text-desc:first-of-type {
    margin-bottom: 16px;
}

.image {
    transform: scale(1.1) translateX(32px);
}

@media screen and (max-width: 425px) {
    .row {
        padding: 48px 16px;
    }

    .title {
        font-size: 24px!important;
        line-height: 140%!important;
        margin-right: 0!important;
    }
    
    .text {
        font-size: 14px!important;
        line-height: 140%!important;
    }

    .description {
        width: 100%
    }

    .text-desc {
        font-size: 12px!important;
        line-height: 140%!important;
    }

    .image {
        transform: scale(1) translateX(0px);
    }
}