.bg {
    background-color: var(--grey);
}

.col-content {
    margin-top: 86px;
    margin-bottom: 173px;
}

.show-tablet {
    display: none;
}

.row-text {
    padding: 0 15px;
}

@media screen and (max-width: 768px) {
    .col-content {
        margin-top: 26px;
        margin-bottom: 150px;
    }

    .show-tablet {
        display: block;
    }

    .row-text > h3 {
        font-size: 20px;
        line-height: 110%;
    }   
    
    .row-text > p {
        font-size: 14px;
        line-height: 140%;
    }   
}