.col-img {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/images/capa-login.png);
    background-size: cover;
    background-position: center;
}

.col-img img {
    position: absolute;
    bottom: 42px;
}

.logo-dark {
    margin-bottom: 56px;
}

.back-button {
    gap: 14px;
    margin-bottom: 24px;
    color: var(--greyscale-03);
    border: none;
    background-color: transparent;
}

.back-button:hover {
    color: var(--greyscale-03);
}

.back-button > icon {
    color: var(--greyscale-03);
    font-size: 12px;
    line-height: 140%;
}

.back-button > .back-button-text {
    color: var(--greyscale-03);
}

.select-wrapper {
    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
    margin-bottom: 16px;
}

.select {
    border: none!important;
    border-right: 18px solid transparent!important;
    margin-bottom: 0!important;
}

.select:active {
    background: #F2F2F2;
    color: #808080;
}

.bottom-panel {
    /* position: absolute; */
    margin-top: 45px;
    margin-bottom: 32px;
    width: 100%;
    /* bottom: 52px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #666666;
}

@media screen and (min-width: 767px) and (max-height: 910px) {
    .col-img {
        top: 0;
        position: sticky;
    }

    /* .bottom-panel {
        position: relative;
        bottom: 0;
        margin-top: 45px;
        margin-bottom: 32px;
    } */
}

@media screen and (max-width: 1024px) {
    /* .bottom-panel {
        position: relative;
        bottom: 0;
        margin-top: 45px;
        margin-bottom: 32px;
    } */
}

@media screen and (max-width: 767px) {
    .col-img {
        height: 160px;
    }

    .col-img img {
        bottom: 24px;
    }

    .back-button {
        position: absolute;
        top: -140px;
    }

    .back-button {
        color: var(--white);
    }
    
    .back-button:hover {
        color: var(--white);
    }
    
    .back-button > icon,
    .back-button > .back-button-text {
        color: var(--white);
    }

    .col-login img:nth-child(1) {
        display: none;
    }

    .logo-dark {
        display: none;
    }
}