.modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1025;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.modal {
    max-width: 444px;
    background-color: var(--white);
    border: 1px solid #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 48px;
}

.title-config {
    font-size: 20px;
    margin-bottom: 24px;
}

.body {
    flex-direction: column;
    gap: 48px;
}

.col-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .modal {
        padding: 24px 16px 48px;
    }

    .body {
        gap: 40px;
    }
}