.show-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .show-desktop {
        display: none;
    }

    .show-mobile {
        display: block;
    }
}