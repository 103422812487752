.circle {
    width: 40px;
    height: 40px;
    background-color: var(--greyscale-09);
    border: 1px solid var(--tertiary-06);
    border-radius: 50%;
    margin-right: 12px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--secondary-02);

    display: flex;
    justify-content: center;
    align-items: center;
}

.big {
    width: 112px;
    height: 112px;
    border: 3px solid var(--tertiary-06);
    font-size: 40px;
    line-height: 110%;
}

@media screen and (max-width: 425px) {
    .big {
        width: 65px;
        height: 65px;
    }
}