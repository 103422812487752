.mb-section {
    margin-bottom: -200px;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.data-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 24px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #eee;
}

.list {
    padding: 0;
    list-style: none;
}

.manage-nav {
    display: flex;
    width: 100%;
    gap: 24px;
}

.manage-nav > * {
    flex-grow: 1;
    text-align: center;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.2px;
    color: var(--greyscale-04);

    padding-bottom: 5px;
    cursor: pointer;
}

.manage-nav > .active {
    font-weight: 600;
    color: var(--primary-06);
    border-bottom: 1px solid var(--primary-06);
}

.subscription-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #EEEEEE;
}

.subscription-data:last-of-type {
    border-bottom: none;
}

.subscription-image {
    width: 64px;
    height: 64px;
    border-radius: 3px;
    object-fit: cover;
}

.subscription-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 24px;
}

.subscription-text > div {
    gap: 24px;
}

.status {
    margin-left: 24px!important;
    margin-right: 24px!important;
    width: max-content;
}

.gear {
    color: var(--greyscale-03);
}

.show-sm {
    display: none;
}

.select-wrapper {
    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
    margin-bottom: 16px;
}

.select {
    border: none!important;
    border-right: 18px solid transparent!important;
    margin-bottom: 0!important;
}

.select:active {
    background: #F2F2F2;
    color: #808080;
}

@media screen and (max-width: 767px) {
    .subscription-data {
        flex-wrap: wrap;
    }

    .subscription-col-2 {
        flex-wrap: wrap;
        margin-left: 88px;
    }

    .status {
        width: 100%;
        margin-left: 0!important;
        margin-right: 0!important;
    }

    .subscription-gear {
        display: flex;
        margin-top: 13px;
    }

    .gear {
        margin-right: 13px;
    }


    .show-sm {
        display: block;
    }
}

@media screen and (max-width: 425px) {
    .mb-section {
        margin-bottom: -50px;
    }

    .data-text-wrapper > p:first-child {
        font-size: 16px!important;
        line-height: 140%!important;
    }

    .data-text-wrapper > p:last-child {
        font-size: 12px!important;
        line-height: 14px!important;
    }

    .subscription-data {
        margin: 0;
    }

    .subscription-data:nth-child(1) {
        padding-top: 0;
    }

    .subscription-text {
        gap: 8px;
        margin-bottom: 12px;
        margin-left: 16px;
    }

    .subscription-col-2 {
        margin-left: 80px;
    }

    .subscription-text > div {
        flex-direction: column;
        gap: 8px;
    }

    .subscription-text > p:nth-child(1) {
        font-size: 16px!important;
        line-height: 140%!important;
        margin-bottom: 4px;
    }

    .subscription-text > p:nth-child(2) {
        font-size: 12px!important;
        line-height: 14px!important;
    }

    .status {
        font-size: 12px!important;
        line-height: 14px!important;
        color: var(--deep-orange);
    }
}

