.col-content {
    margin-top: 65px;
    margin-bottom: 230px;
}

.row-text {
    padding: 0 15px;
    display: flex;
    margin-bottom: 40px;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 64px;
    background-color: var(--white);
    border: 1px solid #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
}

.data-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 24px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin-top: 24px;
}

@media screen and (max-width: 425px) {
    .col-content {
        margin-top: 32px;
        margin-bottom: 96px;
    }

    .title {
        font-size: 20px!important;
        line-height: 110%!important;
    }  
    
    .subtitle {
        font-size: 14px!important;
        line-height: 140%!important;
    }

    .data {
        padding: 32px 16px;
    }

    .data-text-wrapper > p:first-child {
        font-size: 16px!important;
        line-height: 140%!important;
    }

    .data-text-wrapper > p:last-child {
        font-size: 12px!important;
        line-height: 14px!important;
    }
}

