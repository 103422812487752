.btn-primary {
    padding: 12px 40px;
    margin: 0;
    border-radius: 6px;
    border: none;
    background-color: #23B385;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--white);
    box-shadow: none!important;
}

.btn-primary:disabled {
    background-color: #A7E1CE;
}

.btn-primary:hover {
    background-color: #167255;
}

.btn-primary:active {
    background-color: #0E4936!important;
    border: none;
    box-shadow: none;
}

.btn-secondary {
    padding: 12px 40px;
    margin: 0;
    border-radius: 6px;
    border: 1px solid var(--primary-06);
    background-color: var(--white);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--primary-06);
    box-shadow: none!important;
}

.btn-secondary:disabled {
    opacity: 0.3;
    border: 1px solid var(--primary-04);
    color: var(--primary-04);
    background-color: var(--white);
}

.btn-secondary:hover {
    background-color: rgba(28, 72, 35, 0.05);
    border: 1px solid var(--primary-06);
    color: var(--primary-06);
}

.btn-secondary:active {
    background-color: rgba(28, 72, 35, 0.15)!important;
    border: 1px solid var(--primary-06)!important;
    color: var(--primary-06)!important;
    border: none;
    box-shadow: none;
}

.btn-social {
    width: 100%;
    padding: 12px 40px;
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid var(--greyscale-01);
    background-color: var(--white);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--greyscale-01);
    box-shadow: none!important;
}

.btn-social:disabled {
    opacity: 0.3;
    border: 1px solid var(--greyscale-04);
    color: var(--greyscale-04);
    background-color: var(--white);
}

.btn-social:hover {
    background-color: rgba(28, 72, 35, 0.05);
    border: 1px solid var(--greyscale-01);
    color: var(--greyscale-01);
}

.btn-social:active {
    background-color: rgba(28, 72, 35, 0.15)!important;
    border: 1px solid var(--greyscale-01)!important;
    color: var(--greyscale-01)!important;
    border: none;
    box-shadow: none;
}

.btn-primary-outline {
    width: 100%;
    padding: 12px 40px;
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid var(--secondary-02);
    background-color: var(--white);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--secondary-02);
    box-shadow: none!important;
}

.btn-primary-outline:disabled {
    opacity: 0.3;
    border: 1px solid #A7E1CE;
    color: #A7E1CE;
    background-color: var(--white);
}

.btn-primary-outline:hover {
    background-color: rgba(28, 72, 35, 0.05);
    border: 1px solid #167255;
    color: #167255;
}

.btn-primary-outline:active {
    background-color: rgba(28, 72, 35, 0.15)!important;
    border: 1px solid #0E4936!important;
    color: #0E4936!important;
    border: none;
    box-shadow: none;
}

.btn-secondary-outline {
    width: 100%;
    padding: 12px 40px;
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid var(--primary-06);
    background-color: var(--white);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--primary-06);
    box-shadow: none!important;
}

.btn-secondary-outline:disabled {
    opacity: 0.3;
    border: 1px solid #59838A;
    color: #59838A;
    background-color: var(--white);
}

.btn-secondary-outline:hover {
    background-color: rgba(28, 72, 35, 0.05);
    border: 1px solid #59838A;
    color: #59838A;
}

.btn-secondary-outline:active {
    background-color: rgba(28, 72, 35, 0.15)!important;
    border: 1px solid #59838A!important;
    color: #59838A!important;
    border: none;
    box-shadow: none;
}

.btn-red-outline {
    width: 100%;
    padding: 12px 40px;
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid var(--red);
    background-color: var(--white);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--red);
    box-shadow: none!important;
}

.btn-red-outline:disabled {
    opacity: 0.3;
    border: 1px solid var(--red);
    color: var(--red);
    background-color: var(--white);
}

.btn-red-outline:hover {
    background-color: rgba(244, 67, 54, 0.05);
    border: 1px solid var(--red);
    color: var(--red);
}

.btn-red-outline:active {
    background-color: rgba(244, 67, 54, 0.15)!important;
    border: 1px solid var(--red)!important;
    color: var(--red)!important;
    border: none;
    box-shadow: none;
}

.btn-filter, .btn-white {
    width:  190px;
    padding: 8px 0px;
    margin: 0;
    border-radius: 8px;
    border: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
    box-shadow: none!important;
}

.btn-filter,
.btn-filter:hover,
.btn-filter:active,
.btn-white:hover,
.btn-white:active {
    background-color: #D5F1E8;
    color: var(--primary-02);
}

.btn-white {
    background-color: var(--white);
    color: var(--greyscale-04);
}

@media screen and (max-width: 425px) {
    .btn-filter, .btn-white {
        font-size: 14px;
        line-height: 140%;
    }
}