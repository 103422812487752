.card {
    height: 475px;

    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(188, 188, 188, 0.25);
    border-radius: 8px;
    border: none;
}

.card-img-top {
    height: 241px;
    object-fit: cover;
}

@media screen and (max-width: 425px) {
    .card {
        height: 416px;
    }

    .card-img-top {
        height: 180px;
    }
}