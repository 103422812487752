.card {
    height: 415px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(188, 188, 188, 0.25);
    border-radius: 8px;
    border: none;
}

.card-img-top {
    height: 85px;
    object-fit: cover;
}

.card-body {
    padding: 24px 16px;
}

.card-body h6 {
    margin-bottom: 16px;
}

.card-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge {
    margin: -29px auto 0 auto!important;
    padding: 5px 10px!important;
    border-radius: 5px 5px 0px 0px;
}

.price {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    color: var(--primary-01);
    margin-bottom: 0;
}