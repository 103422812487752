.header {
    position: relative;
    z-index: 1;
    background-color: var(--primary-01);
    box-shadow: 0px 4px 4px rgba(231, 231, 231, 0.05);
    z-index: 10;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-01);
    padding: 30px 0;
}