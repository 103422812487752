.modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--grey);
    z-index: 1030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-wrapper > div > iframe {
    width: calc(100vw - 40px);
    height: calc(100vh - 80px);
    margin-top: 20px;
    border: 3px solid var(--greyscale-06);
}
