.title-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    padding-top: 57px;
}

.title-wrapper-plans {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 0;
}

.filter-wrapper {
    margin-bottom: 65px;
}

@media screen and (max-width: 425px) {
    .title-wrapper {
        margin-bottom: 32px;
        padding-top: 66px;
    }
    
    .title-wrapper > h3 {
        font-size: 20px;
        line-height: 110%;
    }

    .filter-wrapper {
        margin-bottom: 40px;
    }
}