.select-wrapper {
    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
    margin-bottom: 16px;
}

.select {
    border: none!important;
    border-right: 18px solid transparent!important;
    margin-bottom: 0!important;
}

.select:active {
    background: #F2F2F2;
    color: #808080;
}