.page-link {
    padding: 0.5rem 0.75rem!important;
    margin-left: -1px!important;
    border: none;
    background-color: transparent;
}

.page-link:hover {
    color: var(--primary-01);
}

.active {
    color: var(--primary-01);
    text-decoration-line: underline;
}