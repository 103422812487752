.header {
    position: relative;
    z-index: 1;
    background-color: var(--primary-01);
    box-shadow: 0px 4px 4px rgba(231, 231, 231, 0.05);
    padding: 19px;
    z-index: 10;
}

.side-menu {
    position: fixed;
    width: calc(100% - 16px);
    height: 100vh;
    top: 0;
    z-index: 10;
    background-color: var(--primary-01);
    border-radius: 0px 8px 0px 0px;
}

.profile {
    padding: 17.5px 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.link {
    color: var(--white);
    text-decoration: underline;
}

.category {
    padding: 24px 16px;
}

.category-items {
    list-style: none;
    padding-left: 0;
}

.category-items > li {
    padding: 18px 0;
}

.search {
    position: relative;
}

.search-input {
    width: 100%;
    padding: 10px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;

    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

.search-list {
    position: absolute;
    width: 100%;
    background-color: var(--white);
    border: 0.5px solid #E5E5E5;
    border-radius: 6px;
    list-style: none;
    padding-left: 16px;
}

.search-list > a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-03);
}