@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
}

.title-h1 {
  font-size: 48px;
  line-height: 110%;
}

.title-h2 {
  font-size: 40px;
  line-height: 110%;
}

.title-h3 {
  font-size: 32px;
  line-height: 110%;
}

.title-h4 {
  font-size: 24px;
  line-height: 110%;
}

.title-h5 {
  font-size: 20px;
  line-height: 110%;
}

.title-h6 {
  font-size: 18px;
  line-height: 110%;
}

.text-body {
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.2px;
  padding: 0;
  margin: 0;
}

.text-xlarge {
  font-size: 18px;
  line-height: 140%;
}

.text-large {
  font-size: 16px;
  line-height: 140%;
}

.text-medium {
  font-size: 14px;
  line-height: 140%;
}

.text-small {
  font-size: 12px;
  line-height: 14px;
}

.text-bold {
  font-weight: 700;
}

.text-semi-bold {
  font-weight: 600;
}

.text-w-medium {
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}